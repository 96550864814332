<template>
  <div>
    <div>
      <el-tooltip
        content="Select a content node to trigger the fallback flow when no matching intents are found."
        placement="top"
      >
        <el-button type="primary" @click="toggleShow"> Set fallback </el-button>
      </el-tooltip>
    </div>

    <el-dialog
      title="Set Fallback"
      :visible.sync="visible"
      width="50%"
      @open="beforeOpenDialog"
      destroy-on-close
    >
      <div>
        <el-autocomplete
          v-model="fallback.response"
          class="autocomplete-list autocomplete-list-target"
          placeholder="Select fallback node"
          :fetch-suggestions="getContentNodes"
          :trigger-on-focus="false"
        >
          <div slot="prepend">
            Fallback Node
            <el-tooltip content="The Node to show if there is no data returned by the API">
              <i class="el-icon-question" />
            </el-tooltip>
          </div>
          <el-button
            v-if="fallback.response"
            slot="append"
            icon="el-icon-d-arrow-right"
            @click="selectNode({ type: 'content', id: fallback.response })"
          />
        </el-autocomplete>
      </div>

      <!-- footer -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">Cancel</el-button>
        <el-button type="primary" @click="handleConfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fallback: {
        response: "",
      },
      visible: false,
    };
  },
  methods: {
    getContentNodes(value, cb) {
      if (value) {
        cb(this.$store.state.search.content.search(value));
      }
    },

    selectNode({ type, id }) {
      this.$eventBus.$emit("editorSwitchTab", "nodes");
      this.$router.push("/editor");
      this.$store.dispatch("SELECT_NODE", { type, id });
    },

    toggleShow() {
      this.visible = !this.visible;
    },

    handleConfirm() {
      this.$store.dispatch("SET_SMART_CLASSIFIER_FALLBACK", this.fallback.response);
      this.visible = false;
    },
    beforeOpenDialog() {
      this.fallback.response = this.$store.state.smart_classifier.fallback;
    },
  },
};
</script>

<style lang="scss" scoped></style>
