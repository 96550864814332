<template>
  <el-input
    v-model="searchText"
    :placeholder="placeholder"
    class="search-bar controll-margin"
    prefix-icon="el-icon-search"
    @change="onChange"
    clearable
  />
</template>
<script>
import Vue from "vue";
import _ from "lodash";

export default Vue.extend({
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    searchText: {
      get() {
        return _.get(this, "$store.state.faq.intentSearch", "");
      },
      set(value) {
        this.$set(this.$store.state.faq, "intentSearch", value);
      },
    },
  },
  mounted() {
    this.searchText = _.get(this.$route, "query.search") || "";
  },
});
</script>

<style scoped>
.search-bar {
  transition-property: width;
  transition-duration: 0.5s;
  margin-bottom: 0 !important;
}
</style>
