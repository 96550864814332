import { RootState } from "@/store/types";
import { Module } from "vuex";
import actions from "./actions";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import { SmartClassifierState } from "./types";

const smart_classifier: Module<SmartClassifierState, RootState> = {
  // namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
export default smart_classifier;
