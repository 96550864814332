<template>
  <div>
    <el-row>
      <el-col :span="4">
        <el-tooltip content="Add a new intent" placement="right">
          <el-button type="primary" @click="showNewIntentDialog">Add new intent</el-button>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="newIntentDialogVisible">
      <el-row :gutter="10" type="flex" id="faq-new-intent">
        <el-col :span="20">
          <el-input v-model="defaultIntent.intentName" placeholder="Enter a new intent name..." />

          <small class="has-text-danger" v-if="errorMessage" v-html="errorMessage"></small>
        </el-col>
        <el-col :span="2">
          <el-tooltip content="Add a new intent" placement="right">
            <el-button
              type="primary"
              :disabled="!defaultIntent.intentName"
              icon="el-icon-plus"
              @click="onAddIntent"
            />
          </el-tooltip>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import _ from "lodash";

export default {
  props: {
    intents: {
      default: Array,
    },
  },
  computed: {
    /**
     * @description Get validation error message
     * @return {string | undefined}
     */
    errorMessage() {
      if (!this.$v.$error) {
        return undefined;
      }

      return !this.$v.defaultIntent.intentName.isUnique
        ? "Intent name has been taken."
        : "Intent name is required and has to be at least 3 characters.";
    },
    /**
     * @description Get all intent names into lowercase
     * @return {string[]}
     */
    datasetIntentNames() {
      const dataset = _.chain(this.intents)
        .map((intent) => intent.intentName.toUpperCase())
        .value();
      return dataset;
    },
  },
  methods: {
    onAddIntent() {
      if (this.$v.defaultIntent.intentName.$invalid) {
        return;
      }

      this.$emit("handleBlur", this.defaultIntent, true);
      this.$nextTick(() => {
        this.defaultIntent.intentName = "";
        this.defaultIntent.topics = [];
      });
      this.newIntentDialogVisible = false;
    },

    showNewIntentDialog() {
      this.newIntentDialogVisible = true;
      this.$v.defaultIntent.intentName.$touch();
    },
  },
  data() {
    return {
      defaultIntent: {
        intentName: "",
        topics: [],
      },
      newIntentDialogVisible: false,
    };
  },
  validations() {
    return {
      defaultIntent: {
        intentName: {
          required,
          minLength: minLength(3),
          isUnique: (value) => {
            if (!value) {
              return true;
            }

            const upperCasedValue = value.toUpperCase();
            const isUnique = !_.includes(this.datasetIntentNames, upperCasedValue);
            return isUnique;
          },
        },
      },
    };
  },
};
</script>

<style scoped>
#faq-new-intent .el-select {
  width: 100%;
}

#faq-new-intent .el-input {
  margin-bottom: -5px;
}

#faq-new-intent small {
  font-size: 10px;
  margin-left: 3px;
}
</style>
