const mutations = {
  SMART_CLASSIFIER_SET_INTENTS: (state, intents) => {
    state.intents = intents;
  },
  SMART_CLASSIFIER_ADD_NEW_INTENT: (state, intent) => {
    state.intents.push(intent);
  },
  SMART_CLASSIFIER_SET_FALLBACK: (state, fallback) => {
    state.fallback = fallback || "";
  },
  SMART_CLASSIFIER_SET_FETCHING: (state, isFetching) => {
    state.isFetching = isFetching;
  },
  SMART_CLASSIFIER_DELETE_INTENT: (state, index) => {
    state.intents.splice(index, 1);
  },
};

export default mutations;
