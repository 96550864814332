<template>
  <div style="padding: 0px">
    <el-row>
      <el-row>
        <el-col :span="24">
          <EventNodeSelector
            :value="selectedIntent.answer"
            @save-side-effect="$emit('save', { rowId: selectedIntent.id, action: 'MODIFY' })"
          />
        </el-col>
      </el-row>
    </el-row>
    <el-row v-if="this.$store.state.showAdvanced">
      <el-col>
        <JSONEditor v-model="clonedComputedSingleRowData" />
      </el-col>
    </el-row>

    <!-- Node Preview Section -->
    <el-row>
      <el-row>
        <el-col class="text-center">
          <el-card
            class="nodePreviewSection"
            width="100%"
            shadow="never"
            body-style="border: 1px 0px"
          >
            <span class="has-text-dark">Node Preview</span>
          </el-card>
        </el-col>
      </el-row>
      <el-col class="nodePreviewSection">
        <div v-if="selectedNodeData">
          <Bubble v-model="selectedNodeData" />
          <div class="edit-node-btn-section">
            <el-button class="edit-node-btn" @click="selectNode(selectedIntent.answer)">
              Edit node In Editor
            </el-button>
          </div>
        </div>
        <div v-else class="no-preview-section">
          <span>
            This intent has no action.
            <br />Please select an action above.
          </span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FaqAnswerDepartment from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/SingleFaqQuestionDetail/FaqAnswerDepartment.vue";
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import Bubble from "@/components/Bubble.vue";

export default {
  props: {
    selectedIntent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIntentAnswer: {},
      clonedComputedSingleRowData: {},
    };
  },
  methods: {
    selectNode(action) {
      let id = action.data;
      this.$store.dispatch("SELECT_NODE", { type: "content", id });
      this.$router.push("/editor");
    },
  },
  computed: {
    selectedNodeData() {
      const selectedIntent = this.selectedIntent;
      let selectedContentNode;
      const actionEvent = _.get(selectedIntent, "answer.event");
      switch (actionEvent) {
        case "goto":
          {
            const contentNodeID = _.get(selectedIntent, "answer.data");
            selectedContentNode = this.$store.state.nodes.content[contentNodeID];
          }
          break;
        case "capture":
          {
            const { next } = _.get(selectedIntent, "answer.data", {});
            if (next && next.constructor === Object) {
              const { event, data } = next;
              if (event === "goto" && data) {
                selectedContentNode = this.$store.state.nodes.content[data];
              }
            } else if (next) {
              selectedContentNode = this.$store.state.nodes.content[next];
            }
          }
          break;
      }
      if (selectedContentNode) {
        this.$emit("toggleRequiredAnswerFlag", true);
        return selectedContentNode.content;
      } else {
        return "";
      }
    },
  },
  components: {
    EventNodeSelector,
    Bubble,
  },
};
</script>

<style lang="scss" scoped></style>
