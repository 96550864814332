<template>
  <div class="message-wrapper">
    <ResendMessageButton
      style="vertical-align: middle"
      v-if="getMessageStatus(message) === 'error'"
      :resendMessage="resendMessage"
      errorMessage="Resend message"
    />
    <el-popover
      v-model="isOpened"
      ref="faq"
      width="400"
      trigger="click"
      placement="top"
      :disabled="!canAddToFaq"
    >
      <AddToFaq :is-opened="isOpened" :message="message" />

      <div
        style="font-size: 0.875em; display: inline-block"
        slot="reference"
        :class="{
          'cursor-pointer': canAddToFaq,
          'message-bubble-left': isUserMessage,
          'message-bubble-right': !isUserMessage,
        }"
      >
        <PreviewReply @updatePulseRowKey="$emit('updatePulseRowKey', $event)" :message="message" />
        <p v-html="markedText" style="margin-bottom: 0; word-break: break-word"></p>

        <template v-if="buttons.length > 0">
          <br />
          <MessageButtons
            :is-preview="isPreview"
            :content="buttons"
            :button-outline="buttonOutline"
            @sendPostback="$emit('sendPostback', $event)"
          />
        </template>

        <div style="text-align: right; padding: 0 5px 0 5px">
          <i style="font-size: 9px">
            <template v-if="sender">
              <strong style="text-transform: capitalize">{{ sender }}&nbsp;,</strong>
            </template>
            <el-tooltip
              v-if="getMessageStatus(message) === 'normal'"
              class="item"
              effect="dark"
              content="Sent"
              placement="top-start"
            >
              <i class="el-icon-circle-check"></i>
            </el-tooltip>

            <el-tooltip
              v-else-if="getMessageStatus(message) === 'sending'"
              class="item"
              effect="dark"
              content="Sending"
              placement="top-start"
            >
              <i class="el-icon-loading"></i>
            </el-tooltip>
            <el-tooltip
              v-else-if="getMessageStatus(message) === 'error'"
              class="item"
              effect="dark"
              content="Error while sending this message"
              placement="top-start"
            >
              <i class="el-icon-error has-text-danger"></i>
            </el-tooltip>
            {{ messageTimestamp(message.Timestamp || message.date_created) }}
          </i>
        </div>
        <slot></slot>
      </div>
    </el-popover>
    <!-- <template v-if="isBusy">
      <i class="message-bubble-status el-icon-loading has-text-danger"></i>
    </template>
    <template v-else>
      <i
        @click.prevent="resendMessage"
        v-if="message.type === 'agent' && !isSent"
        class="message-bubble-status el-icon-warning-outline has-text-danger cursor-pointer"
      ></i>
    </template>-->
  </div>
</template>

<script>
import { mdToHtml } from "@/helpers/markdown";
import AddToFaq from "./AddToFaq";
import MessageButtons from "./Buttons";
import ResendMessageButton from "./ResendMessageButton";
import moment from "moment";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";
import { MessageStatus } from "@/store/modules/livechat/types";
import _ from "lodash";
import PreviewReply from "../PreviewReply";

export default {
  props: [
    "content",
    "buttons",
    "isUserMessage",
    "message",
    "buttonOutline",
    "hasMoreThanOneAgentInChat",
    "isPreview",
  ],
  components: {
    AddToFaq,
    MessageButtons,
    ResendMessageButton,
    PreviewReply,
  },
  data() {
    return {
      isOpened: false,
      isSent: false,
      isBusy: false,
    };
  },
  methods: {
    getMessageStatus(message) {
      if (message.status === MessageStatus.SENDING) return "sending";
      if (message.status === MessageStatus.ERROR) return "error";
      return "normal";
    },
    messageTimestamp(timestamp) {
      const result = moment(timestamp).local().calendar(moment(), {
        sameDay: "[Today at] h:mm A",
        nextDay: "[Tomorrow at] h:mm A",
        nextWeek: "[Next] dddd [at] h:mm A",
        lastDay: "[Yesterday at] h:mm A",
        lastWeek: "DD-MMM-YYYY [at] h:mm A",
        sameElse: "DD-MMM-YYYY [at] h:mm A",
      });
      return result;
    },

    /**
     * @description Resend message
     * @return {void}
     */
    resendMessage: _.debounce(
      function () {
        return this.$store.dispatch("RESEND_MESSAGE", {
          messageId: this.message.RowKey,
        });
      },
      500,
      { leading: true }
    ),
  },
  computed: {
    /**
     * @description Message sender name
     * @return {string}
     */
    sender() {
      if (!this.hasMoreThanOneAgentInChat || this.isUserMessage) {
        return null;
      }

      return _.get(this.message, "meta.name", null);
    },

    /**
     * @description Can add faq
     * @return {boolean}
     */
    canAddToFaq() {
      const isAuthorizedForFAQ = checkRoles(getPermittedRoles("faq2"));

      return this.message.type === "message" && isAuthorizedForFAQ;
    },

    /**
     * @description Sanitize html content
     * @return {string}
     */
    markedText() {
      if (typeof this.content !== "string") {
        return;
      }

      return mdToHtml(this.content);
    },
  },
};
</script>

<style lang="scss">
.message-wrapper {
  display: inline-block;
  margin: 0 16px 10px 16px;
  p {
    margin-top: 0;
    margin-bottom: 16px;
    line-height: 1.2;
    font-size: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-top: 0;
    margin-bottom: 8px;
    margin-left: 2px;
  }

  a {
    text-decoration: underline !important;
  }

  a,
  a:visited,
  p,
  li {
    color: inherit;
  }

  img {
    border-radius: 15px;
    max-width: 100%;
  }
}

.message-bubble-status {
  font-size: 14px;
  position: absolute;
  right: 2px;
  bottom: 3px;
  z-index: 10;
  font-weight: bold;
}

.message-bubble-left {
  position: relative;
  padding: 10px;
  background: #0b93f6;
  border-radius: 15px;
  color: white;
  text-align: left;
  min-width: 80px;

  &:before {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: -7px;
    height: 20px;
    border-left: 20px solid #0b93f6;
    border-bottom-right-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 3;
    bottom: -2px;
    left: 4px;
    width: 26px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
  }
}

.message-bubble-right {
  position: relative;
  padding: 10px;
  color: black;
  background: #e5e5ea;
  min-width: 80px;
  border-radius: 15px;
  text-align: left;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -2px;
    right: -7px;
    height: 20px;
    border-right: 20px solid #e5e5ea;
    border-bottom-left-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    right: -56px;
    width: 26px;
    height: 20px;
    background: white;
    border-bottom-left-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
  }
}
</style>
