<template>
  <div>
    <el-row :gutter="5" type="flex">
      <el-input
        v-model="searchText"
        placeholder="Search for intents"
        class="search-bar controll-margin"
        prefix-icon="el-icon-search"
        clearable
      />

      <div class="controll-margin">
        <SmartClassifierSetFallback />
      </div>

      <el-tooltip content="Import as CSV" placement="top">
        <el-button
          class="controll-margin"
          type="primary"
          size="small"
          @click="dialogUploadVisible = true"
        >
          <img class="importBtnIcon" src="img/icons/imp-exp/imp2.png" />
        </el-button>
      </el-tooltip>
      <el-tooltip content="Export as CSV" placement="top">
        <el-button class="exportAddNewBtn importExportBtn" plain @click="exportCSV">
          <img class="importBtnIcon" src="img/icons/imp-exp/exp2.png" />
        </el-button>
      </el-tooltip>
    </el-row>

    <el-row>
      <el-col :md="24" :lg="12" class="first-section-content">
        <SmartClassifierDataSetList
          :intents="searchedIntents"
          :currentSelectIntent="currentSelectIntent"
          :isFetchingIntents="isFetchingIntents"
          @handleIntentEdit="handleIntentEdit"
          @handleAddIntent="handleAddIntent"
        />
      </el-col>

      <el-col :md="24" :lg="12" class="detail-section-content">
        <DefaultPageWithIcon v-if="!currentSelectIntent" :visible-dataset="intents" />
        <SingleClassifierDetails v-else :currentSelectIntent="currentSelectIntent" />
      </el-col>
    </el-row>

    <el-dialog
      @close="
        () => {
          uploadShouldUploadAll = false;
          uploadShouldUploadAllConfirm = null;
        }
      "
      class="import_popup"
      title="Import as CSV"
      :visible.sync="dialogUploadVisible"
      :before-close="handleClose"
      :close-on-press-escape="false"
      width="50%"
    >
      <el-row :gutter="10">
        <el-col>
          <el-upload
            drag
            class="upload-demo"
            action="#"
            :before-upload="beforeUpload"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :file-list="fileList"
          >
            <div class="el-upload__text file-upload-inner-text">Drag file / click to upload</div>
          </el-upload>
        </el-col>
      </el-row>
      <div style="display: flex; margin-bottom: 15px">
        <el-checkbox v-model="uploadShouldUploadAll">Replace All</el-checkbox>
        <el-input
          v-if="uploadShouldUploadAll"
          placeholder="Please type 'confirm' to replace all faq..."
          class="ml-15"
          v-model="uploadShouldUploadAllConfirm"
          size="mini"
        ></el-input>
      </div>
      <el-row>
        <el-col>
          <el-button size="mini" class="w-30" plain type="primary" @click="downloadTemplate">
            Download CSV Template
          </el-button>
          <el-button size="mini" class="w-15" @click="handleClose(false)">Cancel</el-button>
          <el-tooltip class="item" effect="dark" content placement="top-start">
            <div slot="content">
              Q: What happens if I import more than one answer for one question?
              <br />A: Only the first one will be taken and it will OVERWRITE the existing answer
              for that FAQ.
              <br />
              <br />Q: What if the same ID already exist in FAQ? <br />A: Your questions will be
              added on as examples without replacing the current FAQ entirely.
              <br />
              <br />Q: What if there are other columns, do I need to remove them before uploading?
              <br />A: No, they will be automatically ignored, only text under the Id, Question, and
              Answer (case-sensitive) will be processed.
              <br />
              <br />Q: What if I import question(s) and/or example(s) that already exist for that
              FAQ? <br />A: Currently, they will just be added into the example list (duplicate
              checking is still WIP).
              <br />
              <br />Q: I am already uploading a .csv file, but it doesn't recognize it as so. Why?
              <br />A: Check whether the first column of the file is ID instead of Id, this is an
              issue that will detect the file as SYLK instead of .csv.
              <br />
              <br />Q: Must the column headers be on the first row? <br />A: Yes.
            </div>
            <el-button size="mini" class="has-text-dark w-15">Help</el-button>
          </el-tooltip>

          <el-tooltip
            v-if="uploadedContentList.length === 0 && uploadedContentMap.size === 0"
            content="Did not detect any content from uploaded file. Please check file again"
            placement="top"
          >
            <el-button
              class="w-30"
              type="primary"
              :disabled="!(uploadedContentMap.size > 0 || uploadedContentList.length > 0) || saving"
              size="mini"
              :loading="saving"
              @click="upload"
            >
              Upload
            </el-button>
          </el-tooltip>
          <el-button
            v-else
            type="primary"
            class="w-30"
            size="mini"
            :disabled="!(uploadedContentMap.size > 0 || uploadedContentList.length > 0) || saving"
            @click="upload"
            :loading="saving"
          >
            Upload
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import SmartClassifierDataSetList from "./SmartClassifierDataSetList.vue";
import SingleClassifierDetails from "./SingleClassifierDetails.vue";
import DefaultPageWithIcon from "./DefaultPageWithIconClassifier.vue";
import SmartClassifierSetFallback from "./SmartClassifierSetFallback.vue";
import XLSX from "xlsx";
import { setActionData } from "@/helperMethods/faq";

export default {
  components: {
    SmartClassifierDataSetList,
    SingleClassifierDetails,
    DefaultPageWithIcon,
    SmartClassifierSetFallback,
  },
  data() {
    return {
      currentSelectIntent: null,
      isFetchingIntents: false,
      searchText: "",
      uploadShouldUploadAll: false,
      uploadShouldUploadAllConfirm: null,
      fileList: [],
      uploadedContentList: [],
      dialogUploadVisible: false,
      uploadedContentMap: new Map(),
      saving: false,
    };
  },
  methods: {
    handleAddIntent(defaultIntent) {
      const newIntent = {
        intentName: defaultIntent.intentName,
        description: "",
        enabled: false,
        answer: {},
        edit: false,
      };
      this.$store.commit("SMART_CLASSIFIER_ADD_NEW_INTENT", newIntent);
    },
    handleIntentEdit(intent) {
      this.currentSelectIntent = intent;
    },
    exportCSV() {
      if (this.intents.length === 0) {
        this.$message({
          message: "No intents to export",
          type: "warning",
        });
        return;
      }

      // use XLXS library to export data
      const data = this.intents.map((intent) => {
        return {
          Intent: intent.intentName,
          Description: intent.description,
          Enabled: intent.enabled ? "Yes" : "No",
          AnswerEvent: intent.answer?.event,
          AnswerData: setActionData(intent.answer),
        };
      });

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "intents.csv");
    },

    beforeUpload(file) {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
      if (isCSV) {
        this.handleSuccess("", file);
      } else {
        this.$message.error("Uploaded file must be in CSV format!");
      }
      return isCSV;
    },

    handleError(err) {
      // console.log("An error occurred while importing file!"); -> Unexpected console statement
      // console.log(err);  -> Unexpected console statement
    },

    handleRemove() {
      this.fileList = [];
      this.uploadedContentList = [];
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },

    handleClose(done) {
      this.dialogUploadVisible = false;
      this.handleRemove();

      if (done) done();
    },

    handleSuccess(res, file) {
      this.uploadedContentMap = new Map();
      this.uploadedContentList = [];

      const reader = new FileReader();
      reader.onload = ((vuecomponent) => {
        return function (e) {
          const data = e.target.result;
          const wb = XLSX.read(data, {
            type: "binary",
            raw: true,
          });
          const firstSheetName = wb.SheetNames[0];
          const firstSheet = wb.Sheets[firstSheetName];
          const uploadedData = XLSX.utils.sheet_to_json(firstSheet); // Only get the first sheet
          console.log(uploadedData);
          uploadedData.forEach((rawRow, index) => {
            const row = vuecomponent.transformObjectToLowerCase(rawRow, []);

            const { answerdata, answerevent, description } = row;
            if (description != null || row.intent != null) {
              const enabled = /yes/i.test(row.enabled);
              let intent = vuecomponent.uploadedContentMap.get(intent);

              if (!intent) {
                intent = {
                  intentName: row.intent,
                  description: description,
                  enabled: enabled,
                  answer: {
                    event: answerevent,
                    data: answerdata,
                  },
                };

                vuecomponent.uploadedContentMap.set(intent, intent);
              }

              // TODO: once we have to add more variables to be updated, we will refactor
              let newAction = {
                event: row.answerevent,
                data: row.answerdata,
              };

              if (row.event === "callapi") {
                newAction = {
                  event: "callApi",
                  data: {
                    mappingName: row.data,
                  },
                };
              }

              const updatedIntent = Object.assign({}, intent, {
                answer: newAction,
              });
              vuecomponent.uploadedContentMap.set(row.intent, updatedIntent);
            }
          });

          vuecomponent.uploadedContentList = Array.from(vuecomponent.uploadedContentMap.values());
        };
      })(this);
      reader.readAsText(file);

      this.$forceUpdate();
      this.fileList = [...this.fileList, file];
    },

    async upload() {
      const isAllowToSave =
        !this.uploadShouldUploadAll ||
        (this.uploadShouldUploadAll && this.uploadShouldUploadAllConfirm === "confirm");

      if (this.saving || !isAllowToSave) {
        return;
      }

      try {
        this.saving = true;
        let datasetToSave = [];
        if (!this.uploadShouldUploadAll) {
          datasetToSave = _.cloneDeep(this.$store.state.smart_classifier.intents);
          this.uploadedContentList.forEach((newIntentRow) => {
            if (newIntentRow.intentName) {
              const intentFound = _.find(datasetToSave, (oldIntent) => {
                if (oldIntent.intentName.toUpperCase() === newIntentRow.intentName.toUpperCase()) {
                  return true;
                }
              });

              if (intentFound) {
                const intentWithUpdate = Object.assign({}, intentFound, newIntentRow);

                const intentIndex = _.findIndex(datasetToSave, (oldIntent) => {
                  return (
                    oldIntent.intentName.toUpperCase() === newIntentRow.intentName.toUpperCase()
                  );
                });

                this.$store.state.smart_classifier.intents[intentIndex] = intentWithUpdate;
              } else {
                datasetToSave.unshift(newIntentRow);
                this.$store.state.smart_classifier.intents.unshift(newIntentRow);
              }
            }
          });
        } else {
          datasetToSave = this.uploadedContentList.map((newIntentRow) => newIntentRow);
          this.$store.state.smart_classifier.intents = datasetToSave;
        }

        await this.save();
        this.uploadShouldUploadAll = false;
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: "Save Error",
          position: "bottom-right",
          message: "Failed to save FAQ dataset.",
        });
      } finally {
        this.handleClose(false);
        this.saving = false;
      }
    },

    downloadTemplate() {
      var link = document.createElement("a");
      link.download = "FAQ_template.csv";
      link.href = "/template/FAQ_template.csv";
      link.click();
    },

    transformObjectToLowerCase(obj, exclude = []) {
      return _.transform(obj, (result, value, _key) => {
        const key = _key.toLowerCase();
        result[key] = exclude.includes(key) ? value : value.toLowerCase();
      });
    },

    async save() {
      const result = await this.$store.dispatch(
        "SMART_CLASSIFIER_SAVE_INTENT",
        this.$store.state.brain
      );
      return result;
    },
  },
  computed: {
    intents() {
      return this.$store.state.smart_classifier.intents;
    },
    searchedIntents() {
      if (this.intents.length === 0) {
        return [];
      }

      return this.intents.filter((intent) => {
        return intent.intentName.toLowerCase().includes(this.searchText.toLowerCase());
      });
    },
  },
  async created() {
    await this.$store.dispatch("SMART_CLASSIFIER_FETCH_INTENTS", this.$store.state.brain);
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";

.controll-margin {
  margin-right: 8px;
}

.importBtnIcon {
  height: 18px;
  width: 18px;
}

.import_popup {
  padding: 20px;
}

.file-upload-inner-text {
  font-size: 20px;
  display: block;
  height: 50px;
  text-align: center;
  line-height: 160px;
  color: $color-dark;
}

.exportAddNewBtn {
  padding: 10px;
}
.importBtnIcon {
  height: 18px;
  width: 18px;
}

.first-section-content {
  height: 81vh;
  overflow-y: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.w-30 {
  width: 30%;
}

.w-15 {
  width: 15%;
}

.ml-15 {
  margin-left: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.w-full {
  width: 100%;
}
</style>
