<template>
  <div style="height: 100%">
    <!-- LEFT COL -->
    <el-col
      :span="8"
      :xs="24"
      style="height: 100%"
      id="content-node-editor-right"
      :class="{
        show: shouldShowLeftSection,
      }"
    >
      <div style="text-align: right; padding: 0 20px">
        <el-button
          @click="toggleShowLeftSection"
          type="danger"
          plain
          icon="el-icon-close"
          circle
          size="small"
          id="content-node-editor-close-button"
        ></el-button>
      </div>
      <NodesList @select="selectNode" />
    </el-col>

    <!-- RIGHT COL -->
    <el-col :span="16" :xs="24" style="height: 100%; overflow: auto">
      <div
        v-if="!shouldShowLeftSection"
        style="margin-bottom: 10px"
        id="content-node-editor-list-button"
      >
        <el-button @click="toggleShowLeftSection" type="primary" plain>Content Node List</el-button>
      </div>
      <div class="nodes-editor">
        <ContentNodeEditor
          v-if="activeNodeType === 'content'"
          :value="activeNodeId"
          @select="selectNode"
        />
        <TriggerNodeEditor
          v-else-if="activeNodeType === 'trigger'"
          :id="activeNodeId"
          @select="selectNode"
        />
      </div>
    </el-col>
  </div>
</template>

<script>
import NodesList from "@/components/NodesList/Index.vue";
import ContentNodeEditor from "@/components/ContentNodeEditor.vue";
import TriggerNodeEditor from "@/components/TriggerNodeEditor.vue";

export default {
  name: "NodesEditor",
  components: {
    NodesList,
    ContentNodeEditor,
    TriggerNodeEditor,
  },
  data() {
    return {
      shouldShowLeftSection: false,
    };
  },
  props: [],
  computed: {
    activeNodeType() {
      return this.$store.state.activeNodeType;
    },
    activeNodeId() {
      return this.$store.state.activeNodeId;
    },
  },
  methods: {
    selectNode({ type, id }) {
      this.$store.dispatch("SELECT_NODE", { type, id });
    },
    toggleShowLeftSection() {
      this.shouldShowLeftSection = !this.shouldShowLeftSection;
    },
  },
};
</script>

<style lang="scss">
.nodes-editor {
  height: 90vh;
  overflow: auto;
  // @media screen and (max-width: 1440px) {
  //   height: 71vh;
  // }
}

#content-node-editor-right {
  //  hiding for xs screens
  @media screen and (max-width: 768px) {
    display: none;
  }

  &.show {
    display: block;
  }
}

#content-node-editor-list-button {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 10px;
  }
}

#content-node-editor-close-button {
  display: none;
  @media screen and (max-width: 768px) {
    display: inline-block;
  }
}
</style>
