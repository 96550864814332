<template>
  <div>
    <!-- Save button -->
    <el-button :loading="isLoading" type="success" plain @click="handleSaveSmartClassifier"
      >Save changes</el-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async handleSaveSmartClassifier() {
      try {
        this.isLoading = true;
        const result = await this.$store.dispatch("SMART_CLASSIFIER_SAVE_INTENT", {
          brain: this.brain,
        });

        if (result) {
          this.$message.success("Smart classifier saved successfully");
        } else {
          this.$message.error("Error saving smart classifier");
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$message.error("Error saving smart classifier");
      }
    },
  },
  computed: {
    brain() {
      return this.$store.state.brain;
    },
  },
};
</script>

<style lang="scss" scoped></style>
